export const ROOT_UID = "spm-19xx";

const somtimehta = ROOT_UID,
  bimlamehta = "bm-19xx",
  neerajmehta = "nm-19xx",
  sangeetamehta = "sg-19xx",
  rumamehta = "rm-198x",
  anoopramachandran = "an-198x",
  pranavramachandran = "pr-201x",
  devanshmehta = "dv-1989",
  rakshitamehta = "rk-199x",
  sanjeevkumarmehta = "skm-19xx",
  manishashubhramehta = "mm-19xx",
  kanikamehta = "km-1991",
  adityapushkarna = "ad-198x",
  adikapushkarna = "ad-2021",
  utkarshmehta = "um-1996",
  bhoorimehta = "bh-2020",
  damrumehta = "dm-2021",
  pradeepmehta = "pr-19xx",
  chandrikamehta = "ch-19xx",
  irikamehta = "ir-199x",
  ishitmehta = "is-1999",
  neetamehta = "ntm-19xx",
  ashoksharma = "as-19xx",
  akshaysharma = "ak-1992",
  niharikasharma = "nh-199x",
  arlosharma = "ar-201x",
  udishasharma = "ud-1997",
  manojmehta = "mn-19xx",
  manishamehta = "ma-19xx",
  gaurangmehta = "gu-2000",
  gaurimehta = "gu-200x",
  zaramehta = "zr-200x";

export const FAMILY = [
  {
    id: somtimehta,
    name: "Somti Prakash Mehta",
    sex: "M",
    status: "expired",
    DOB: "17 Jan",
    DOD: "DD MMM, YYYY",
    marriedTo: bimlamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/somti_mehta_rKImde8ni.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1643126810657",
  },
  {
    id: bimlamehta,
    name: "Bimla Mehta",
    sex: "F",
    status: "active",
    DOB: "02 Feb",
    marriedTo: somtimehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/bimla_mehta_wfxEvClY00R.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1643126810676",
  },
  {
    id: neerajmehta,
    name: "Neeraj Mehta",
    childOf: [somtimehta, bimlamehta],
    sex: "M",
    status: "active",
    DOB: "10 Nov",
    marriedTo: sangeetamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/man_1IChdUpwR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652636752957",
  },
  {
    id: sangeetamehta,
    name: 'Sangeeta Mehta',
    sex: 'F',
    status: 'active',
    DOB: "16 Nov",
    marriedTo: neerajmehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/woman_x-sSlgiMk.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1652636752829",
  },
  {
    id: rumamehta,
    name: 'Ruma Ramachandran',
    sex: 'F',
    status: 'active',
    DOB: "13 Sep",
    marriedTo: anoopramachandran,
    childOf: [neerajmehta, sangeetamehta],
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/ruma_ramachandra_ulWhZSctk.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857311457",
  },
  {
    id: anoopramachandran,
    name: 'Anoop Ramachandran',
    sex: 'M',
    status: 'active',
    DOB: "23 Nov",
    marriedTo: rumamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/man_1IChdUpwR.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1652636752957",
  },
  {
    id: pranavramachandran,
    name: 'Pranav Ramachandran',
    sex: 'M',
    status: 'active',
    DOB: "5 May",
    childOf: [rumamehta, anoopramachandran],
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/pranav_ramachandran_90T6YY41FZ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881498",
  },
  {
    id: devanshmehta,
    name: 'Devansha Mehta',
    sex: 'M',
    status: 'active',
    DOB: "9 Dec",
    marriedTo: rakshitamehta,
    childOf: [neerajmehta, sangeetamehta],
    nickname: "Subhi",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/devansh_mehta_RLOv5FfQb5.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881586",
  },
  {
    id: rakshitamehta,
    name: 'Rakshita Mehta',
    sex: 'F',
    status: 'active',
    DOB: "29 Sep",
    marriedTo: devanshmehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/rakshita_mehta_flC8vH-cekc5.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881643",
  },
  {
    id: sanjeevkumarmehta,
    name: "Sanjeev Kumar Mehta",
    childOf: [somtimehta, bimlamehta],
    sex: "M",
    status: "active",
    DOB: "18 Aug",
    marriedTo: manishashubhramehta,
    nickname: "Sanju",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/sanjeev_mehta_WTFDQuSsT.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857311917",
  },
  {
    id: manishashubhramehta,
    name: "Manisha Mehta",
    sex: "F",
    status: "active",
    DOB: "15 Nov",
    marriedTo: sanjeevkumarmehta,
    nickname: "Shubhra",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/manisha_shubhra_mehta__kiWw76Fzu-X.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857312163",
  },
  {
    id: kanikamehta,
    name: "Kanika Mehta",
    childOf: [sanjeevkumarmehta, manishashubhramehta],
    sex: "F",
    status: "active",
    DOB: "12 May, 1991",
    nickname: "Kanu",
    marriedTo: adityapushkarna,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/kanika_pushkarna_jHrvwL4XI-.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857312031",
  },
  {
    id: adityapushkarna,
    name: 'Aditya Pushkarna',
    sex: 'M',
    status: 'active',
    DOB: "17 May",
    marriedTo: kanikamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/aditya_pushkarna_VOKjitY9W6R.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642872602698",
  },
  {
    id: adikapushkarna,
    name: "Adika Pushkarna",
    childOf: [kanikamehta, adityapushkarna],
    sex: "F",
    status: "active",
    DOB: "13 Aug, 2019",
    nickname: "Bontu",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/adika_pushkarna_oBjTDMdh3.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642872602640",
  },
  {
    id: utkarshmehta,
    name: "Utkarsh Mehta",
    childOf: [sanjeevkumarmehta, manishashubhramehta],
    sex: "M",
    status: "active",
    DOB: "5 Oct, 1996",
    nickname: "Kush",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/utkarsh_mehta.JPG?ik-sdk-version=javascript-1.4.3&updatedAt=1652636869099",
  },
  {
    id: bhoorimehta,
    name: "Bhoori Mehta",
    childOf: [utkarshmehta],
    sex: "F",
    status: "expired",
    DOB: "5 Sept, 2020",
    DOD: "11 Oct, 2021",
    nickname: "Bhuru",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/bhoori_mehta_9BWadtHX2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1642872838877",
  },
  {
    id: damrumehta,
    name: "Damru Mehta",
    childOf: [utkarshmehta],
    sex: "M",
    status: "active",
    DOB: "1 Aug, 2021",
    nickname: "Babi",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/damru_mehta_lksEo-_eKO.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642872602800",
  },
  {
    id: pradeepmehta,
    name: "Pradeep Mehta",
    childOf: [somtimehta, bimlamehta],
    sex: "M",
    status: "active",
    DOB: "11 Oct",
    marriedTo: chandrikamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/pradeep_mehta_Q3ox5U1fD.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857311436",
  },
  {
    id: chandrikamehta,
    name: "Chandrika Mehta",
    sex: "F",
    status: "active",
    DOB: "23 Sep",
    nickname: "Chinu",
    marriedTo: pradeepmehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/woman_x-sSlgiMk.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1652636752829",
  },
  {
    id: irikamehta,
    name: "Irika Mehta",
    childOf: [pradeepmehta, chandrikamehta],
    sex: "F",
    status: "active",
    DOB: "7 May",
    nickname: "Chinki",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/irika_mehta_JyHPZ-qT8YI.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857311793",
  },
  {
    id: ishitmehta,
    name: "Ishit Mehta",
    childOf: [pradeepmehta, chandrikamehta],
    sex: "M",
    status: "active",
    DOB: "1 Mar",
    nickname: "Joey",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/ishit_mehta_fcatoPyeQ.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857310847",
  },
  {
    id: neetamehta,
    name: "Neeta Sharma",
    childOf: [somtimehta, bimlamehta],
    sex: "F",
    status: "active",
    DOB: "11 Oct",
    nickname: "Neetu",
    marriedTo: ashoksharma,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/neeta_sharma_JpbC8EL_Du.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881487",
  },
  {
    id: ashoksharma,
    name: "Ashok Sharma",
    sex: "M",
    status: "active",
    DOB: "18 Apr",
    marriedTo: neetamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/ashok_sharma_i20L1GTbO.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881423",
  },
  {
    id: akshaysharma,
    name: "Akshay Sharma",
    childOf: [neetamehta, ashoksharma],
    sex: "M",
    status: "active",
    DOB: "17 Mar",
    nickname: "Krishna",
    marriedTo: niharikasharma,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/akshay_sharma_jQLCHFlRE.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881493",
  },
  {
    id: niharikasharma,
    name: "Niharika Sharma",
    sex: "F",
    status: "active",
    DOB: "3 Sep",
    marriedTo: akshaysharma,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/niharika_sharma_HsGODeIh7hC.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881508",
  },
  {
    id: arlosharma,
    name: "Arlo Shamra",
    childOf: [akshaysharma, niharikasharma],
    sex: "M",
    status: "active",
    DOB: "1 Sep",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/pet_yE-peHN1j.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652636927676",
  },
  {
    id: udishasharma,
    name: "Udisha Sharma",
    childOf: [neetamehta, ashoksharma],
    sex: "F",
    status: "active",
    DOB: "20 Oct",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/udisha_sharma_RjaOEL1I5z.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881495",
  },
  {
    id: manojmehta,
    name: "Manoj Mehta",
    childOf: [somtimehta, bimlamehta],
    sex: "M",
    status: "active",
    DOB: "1 May",
    marriedTo: manishamehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/manoj_mehta_ojnYyvSKdsr.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857312388",
  },
  {
    id: manishamehta,
    name: "Manisha Mehta",
    sex: "F",
    status: "active",
    DOB: "16 Sep",
    marriedTo: manojmehta,
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/manisha_mehta_b7G2RfGjb.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857312407",
  },
  {
    id: gaurangmehta,
    name: "Gaurang Mehta",
    childOf: [manojmehta, manishamehta],
    sex: "M",
    status: "active",
    DOB: "20 May",
    nickname: "Gaurangi",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/gaurang_mehta_7XFKfPWmjK.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642857312316",
  },
  {
    id: gaurimehta,
    name: "Gauri Mehta",
    childOf: [manojmehta, manishamehta],
    sex: "F",
    status: "active",
    DOB: "25 Oct",
    nickname: "Gauru",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/gauri_mehta_oL8qLGbv0odK.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1642826881513",
  },
  {
    id: zaramehta,
    name: "Zara Mehta",
    childOf: [manojmehta, manishamehta],
    sex: "F",
    status: "active",
    DOB: "18 Jan",
    nickname: "Zorro",
    photo: "https://ik.imagekit.io/bizwem110d/family-tree/pet_yE-peHN1j.png?ik-sdk-version=javascript-1.4.3&updatedAt=1652636927676",
  },
];

export const FindMember = (id) => {
  try {
    return FAMILY.find((mem) => mem.id === id);
  } catch {
    return {};
  }
};

export const FindParents = (id) => {
  try {
    return FAMILY.find((mem) => mem.id === id)["childOf"].map((parid) =>
      FAMILY.find((mem) => mem.id === parid)
    );
  } catch {
    return [];
  }
};

export const FindChildren = (parentid) => {
  try {
    return FAMILY.filter((mem) => "childOf" in mem).filter((mem) =>
      mem.childOf.includes(parentid)
    );
  } catch (err) {
    return [];
  }
};

export const FindCoupleChildren = (p1id, p2id) => {
  try {
    return FAMILY.filter((mem) => "childOf" in mem).filter((mem) =>
      mem.childOf.includes(p1id, p2id)
    );
  } catch {
    return [];
  }
};
